/* eslint-disable react-hooks/exhaustive-deps */
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";

import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  ConvertToDateCurrentTimeZone,
  translateAuto,
} from "../../../../../../_metronic/_helpers/AtHelper";
import Swal from "sweetalert2";
import { apiCall } from "../../Loads/redux";
import { Link, useHistory } from "react-router-dom";
import { ModalIndex } from "../table/modal";
import { GoogleMapnewversion } from "../../../../../../_metronic/_partials/dashboards/GoogleMapCompNewVersion";
import { useSubheader } from "../../../../../../_metronic/layout";
const adapter = new LocalStorage("db");
const db = low(adapter);

export const LoadDetail = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const [LoadInformations, setLoadInformations] = useState(null);
  const [modalBiding, setModalBiding] = useState(false);
  const [loaderBid, setLoaderBid] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [bidData, setBidData] = useState({
    Price: "",
    IsQuickPay: false,
  });
  const suhbeader = useSubheader();
  suhbeader.setTitle("Load Detail");
  const [DetailTrakingGeometryInfo, setDetailTrakingGeometryInfo] = useState(
    []
  );
  const { t } = useTranslation();
  function getLoadInfo(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiUrl}${
        window.location.search === "?privateLoad=true"
          ? `/api/shipments/0?search=${params}`
          : `/api/shipments/${params}`
      }`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result[0] === 200) {
          var data = result[1];
          setCurrentCode(data?.Code);
          setCustomFields(data.CustomFields);
          let point = [
            {
              Latitude: data?.PickupAddress?.Latitude,
              Longitude: data?.PickupAddress?.Longitude,
              City: data?.PickupAddress?.City,
              State: data?.PickupAddress?.State,
              EventName: "Pick-up",
            },
            ...data?.Stops?.map((a) => {
              return {
                Latitude: a?.Latitude,
                Longitude: a?.Longitude,
                City: a?.City,
                State: a?.State,
                EventName: "Stop",
              };
            }),
            {
              Latitude: data?.DestinationAddress?.Latitude,
              Longitude: data?.DestinationAddress?.Longitude,
              City: data?.DestinationAddress?.City,
              State: data?.DestinationAddress?.State,
              EventName: "Drop-off",
            },
          ];
          setLoadInformations({
            data: data,
            PlannedPolyline: [
              {
                Longiture: data?.DestinationAddress?.Longitude,
                Latitude: data?.DestinationAddress?.Latitude,
              },
            ],
          });
          setDetailTrakingGeometryInfo(point);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    if (id) {
      getLoadInfo(id);
    }
  }, [id]);

  useEffect(() => {
    if (LoadInformations?.data?.BidDetails !== null) {
      setBidData({
        Price: LoadInformations?.data?.BidDetails?.Price,
        IsQuickPay: LoadInformations?.data?.BidDetails?.IsQuickPay,
      });
    }
  }, [LoadInformations]);

  const [currentCode, setCurrentCode] = useState("");

  // const suhbeader = useSubheader();
  // suhbeader.setTitle('Detail load #' + currentCode);
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  async function AddBid() {
    try {
      const result = await apiCall({
        method: "POST",
        link: `/api/shipments/AddBid/${id}`,
        body: bidData,
      });
      if ((result?.length > 0 && result[0] === 200) || result === undefined) {
        setModalBiding(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${t("Bid_has_been_added_successfully_to_this_load")} ${
            LoadInformations?.data?.Code
          }`,
          showConfirmButton: false,
          timer: 1500,
        });
        getLoadInfo(id);
      } else {
        if (
          result[1].ModelState !== null &&
          result[1].ModelState !== undefined &&
          result[1].ModelState !== ""
        ) {
          let modelState = result[1].ModelState;
          if (modelState)
            Object.keys(modelState).forEach(function(k) {
              modelState[k].forEach((element) => {
                Swal.fire(t("Error"), translateAuto(element), "error");
              });
            });
        } else if (
          result[1].Message !== null &&
          result[1].Message !== undefined &&
          result[1].Message !== ""
        ) {
          Swal.fire(t("Error"), translateAuto(result[1].Message), "error");
        } else if (
          result[1].error_description !== null &&
          result[1].error_description !== undefined &&
          result[1].error_description !== ""
        ) {
          Swal.fire(
            t("Error"),
            translateAuto(result[1].error_description),
            "error"
          );
        } else {
          Swal.fire(t("Error"), t("Bad_Request"), "error");
        }
      }
      setLoaderBid(false);
    } catch (err) {}
  }
  useEffect(() => {
    return () => {};
  }, [user]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("TaaP")} | {t("Track_Load")}
        </title>
      </Helmet>
      <div className="row">
        <div className="flex-lg-row-fluid col-xl-12 col-lg-12 col-md-12">
          <Row>
            <Col xl="5" lg="5" md="5" xs="12">
              <Card className="card-stretch gutter-b">
                <CardHeader title={`${t("Load_Info")}: #${currentCode}`}>
                  <CardHeaderToolbar>
                    <Link to="/book" className="btn btn-secondary mr-2">
                      <i className="flaticon2-left-arrow-1 pr-2"></i>
                      {t("Back")}
                    </Link>
                    {LoadInformations?.data?.BiddingEnabled &&
                      (LoadInformations?.data?.BidDetails !== null ? (
                        <button
                          className="btn btnPrimaryTaap mr-2"
                          onClick={(e) => {
                            setModalBiding(true);
                            e.preventDefault();
                          }}
                        >
                          {t("Update_bid")}
                        </button>
                      ) : (
                        <button
                          className="btn btnPrimaryTaap mr-2"
                          onClick={(e) => {
                            setModalBiding(true);
                            e.preventDefault();
                          }}
                        >
                          {t("Add_bid")}
                        </button>
                      ))}

                    <button
                      className="btn btnPrimaryTaap mr-2"
                      onClick={async (e) => {
                        try {
                          if (LoadInformations?.data?.IsQuickPay) {
                            Swal.fire({
                              title: t(
                                "this_load_is_available_for_quick_pay_do_you_want_to_opt_for_it"
                              ),
                              showDenyButton: true,
                              confirmButtonText: t("With_quick_pay"),
                              denyButtonText: t("Without_quick_pay"),
                              showCloseButton: true,
                              confirmButtonColor: "#13215f",
                              customClass: {
                                actions: "my-actions",
                                cancelButton: "order-1 right-gap",
                                confirmButton: "order-2",
                                denyButton: "order-3",
                              },
                            }).then(async (result) => {
                              /* Read more about isConfirmed, isDenied below */
                              if (result.isConfirmed) {
                                const result = await apiCall({
                                  method: "POST",
                                  link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                  body: { QuickPayRequested: true },
                                });
                                if (
                                  (result?.length > 0 && result[0] === 200) ||
                                  result === undefined
                                ) {
                                  Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: `${t("Your_load")} (#${
                                      LoadInformations?.data?.Code
                                    }) ${t(
                                      "with_Quick_Pay_has_been_accepted_successfully"
                                    )}`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                  });
                                  //reload
                                  history.push(
                                    "/track-load/" + LoadInformations?.data?.Id
                                  );
                                } else {
                                  if (
                                    result[1].ModelState !== null &&
                                    result[1].ModelState !== undefined &&
                                    result[1].ModelState !== ""
                                  ) {
                                    let modelState = result[1].ModelState;
                                    if (modelState)
                                      Object.keys(modelState).forEach(function(
                                        k
                                      ) {
                                        modelState[k].forEach((element) => {
                                          Swal.fire(
                                            t("Error"),
                                            translateAuto(element),
                                            "error"
                                          );
                                        });
                                      });
                                  } else if (
                                    result[1].Message !== null &&
                                    result[1].Message !== undefined &&
                                    result[1].Message !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(result[1].Message),
                                      "error"
                                    );
                                  } else if (
                                    result[1].error_description !== null &&
                                    result[1].error_description !== undefined &&
                                    result[1].error_description !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(
                                        result[1].error_description
                                      ),
                                      "error"
                                    );
                                  } else {
                                    Swal.fire(
                                      t("Error"),
                                      t("Bad_Request"),
                                      "error"
                                    );
                                  }
                                }
                              } else if (result.isDenied) {
                                const result = await apiCall({
                                  method: "POST",
                                  link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                  body: { QuickPayRequested: false },
                                });
                                if (
                                  (result?.length > 0 && result[0] === 200) ||
                                  result === undefined
                                ) {
                                  Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: `${t("Your_load")} (#${
                                      LoadInformations?.data?.Code
                                    }) ${t("has_been_accepted_succesfully")}`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                  });
                                  //reload
                                  history.push(
                                    "/track-load/" + LoadInformations?.data?.Id
                                  );
                                } else {
                                  if (
                                    result[1].ModelState !== null &&
                                    result[1].ModelState !== undefined &&
                                    result[1].ModelState !== ""
                                  ) {
                                    let modelState = result[1].ModelState;
                                    if (modelState)
                                      Object.keys(modelState).forEach(function(
                                        k
                                      ) {
                                        modelState[k].forEach((element) => {
                                          Swal.fire(
                                            t("Error"),
                                            translateAuto(element),
                                            "error"
                                          );
                                        });
                                      });
                                  } else if (
                                    result[1].Message !== null &&
                                    result[1].Message !== undefined &&
                                    result[1].Message !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(result[1].Message),
                                      "error"
                                    );
                                  } else if (
                                    result[1].error_description !== null &&
                                    result[1].error_description !== undefined &&
                                    result[1].error_description !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(
                                        result[1].error_description
                                      ),
                                      "error"
                                    );
                                  } else {
                                    Swal.fire(
                                      t("Error"),
                                      t("Bad_Request"),
                                      "error"
                                    );
                                  }
                                }
                              }
                            });
                          } else {
                            Swal.fire({
                              title: t("Accept_Load"),
                              text: `${t(
                                "Are_you_sure_you_want_to_accept_this_Load"
                              )} '${LoadInformations?.data?.Code}' ?`,
                              icon: "warning",
                              showCancelButton: true,
                              cancelButtonText: t("Cancel"),
                              confirmButtonColor: "#1BC5BD ",
                              cancelButtonColor: "##80808F",
                              confirmButtonText: t("Accept"),
                            }).then(async (res) => {
                              if (res.isConfirmed) {
                                const result = await apiCall({
                                  method: "POST",
                                  link: `/api/shipments/accept/${LoadInformations?.data?.Id}`,
                                  body: { QuickPayRequested: false },
                                });
                                if (
                                  (result?.length > 0 && result[0] === 200) ||
                                  result === undefined
                                ) {
                                  Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: `${t("Your_load")} (#${
                                      LoadInformations?.data?.Code
                                    }) ${t("has_been_accepted_succesfully")}`,
                                    showConfirmButton: false,
                                    timer: 1500,
                                  });
                                  //reload
                                  history.push(
                                    "/track-load/" + LoadInformations?.data?.Id
                                  );
                                } else {
                                  if (
                                    result[1].ModelState !== null &&
                                    result[1].ModelState !== undefined &&
                                    result[1].ModelState !== ""
                                  ) {
                                    let modelState = result[1].ModelState;
                                    if (modelState)
                                      Object.keys(modelState).forEach(function(
                                        k
                                      ) {
                                        modelState[k].forEach((element) => {
                                          Swal.fire(
                                            t("Error"),
                                            translateAuto(element),
                                            "error"
                                          );
                                        });
                                      });
                                  } else if (
                                    result[1].Message !== null &&
                                    result[1].Message !== undefined &&
                                    result[1].Message !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(result[1].Message),
                                      "error"
                                    );
                                  } else if (
                                    result[1].error_description !== null &&
                                    result[1].error_description !== undefined &&
                                    result[1].error_description !== ""
                                  ) {
                                    Swal.fire(
                                      t("Error"),
                                      translateAuto(
                                        result[1].error_description
                                      ),
                                      "error"
                                    );
                                  } else {
                                    Swal.fire(
                                      t("Error"),
                                      t("Bad_Request"),
                                      "error"
                                    );
                                  }
                                }
                              }
                            });
                          }
                        } catch (err) {}
                      }}
                    >
                      {t("Accept_load")}
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-2x">
                        <li class="nav-item">
                          <a
                            class={
                              "nav-link " +
                              (currentTab === 0 ? "activeLink" : "")
                            }
                            data-toggle="tab"
                            href="#kt_tab_pane_2"
                            onClick={() => setCurrentTab(0)}
                          >
                            <span class="nav-text">Details</span>
                          </a>
                        </li>{" "}
                        {LoadInformations?.data?.Mode === "Drayage" && (
                          <Fragment>
                            <li class="nav-item">
                              <a
                                class={
                                  "nav-link " +
                                  (currentTab === 1 ? "activeLink" : "")
                                }
                                data-toggle="tab"
                                href="#kt_tab_pane_2"
                                onClick={() => setCurrentTab(1)}
                              >
                                <span class="nav-text">Pickup</span>
                              </a>
                            </li>{" "}
                            <li class="nav-item">
                              <a
                                class={
                                  "nav-link " +
                                  (currentTab === 2 ? "activeLink" : "")
                                }
                                data-toggle="tab"
                                href="#kt_tab_pane_2"
                                onClick={() => setCurrentTab(2)}
                              >
                                <span class="nav-text">Delivery</span>
                              </a>
                            </li>{" "}
                            {LoadInformations?.data?.Mode === "Drayage" &&
                            LoadInformations?.data?.IsStayWith === true ? (
                              <li class="nav-item">
                                <a
                                  class={
                                    "nav-link " +
                                    (currentTab === 3 ? "activeLink" : "")
                                  }
                                  data-toggle="tab"
                                  href="#kt_tab_pane_2"
                                  onClick={() => setCurrentTab(3)}
                                >
                                  <span class="nav-text">Empty</span>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}
                      </ul>
                    </Col>
                  </Row>
                  <div className={currentTab === 0 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column">
                            {LoadInformations?.data?.Mode === "Drayage" ? (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  LFD
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.LastFreeDate !==
                                  null
                                    ? moment(
                                        LoadInformations?.data?.LastFreeDate
                                      ).format("MM/DD/YYYY")
                                    : "- - - -"}
                                </span>
                              </>
                            ) : (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Req_Ship_Date")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ReqShipDate !==
                                  null
                                    ? moment(
                                        LoadInformations?.data?.ReqShipDate
                                      ).format("MM/DD/YYYY")
                                    : "- - - -"}
                                </span>
                              </>
                            )}
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("PU_Appointment")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.data?.PuAppointmentDate !==
                              null
                                ? ConvertToDateCurrentTimeZone(
                                    LoadInformations?.data?.PuAppointmentDate
                                  )
                                : "- - - -"}
                            </span>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Departure_Date")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.data?.ShippingDepartureDate !==
                              null
                                ? ConvertToDateCurrentTimeZone(
                                    LoadInformations?.data
                                      ?.ShippingDepartureDate
                                  )
                                : "- - - -"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center col-xl-5 col-lg-5 col-md-6 col-4">
                          <span className="svg-icon svg-icon-2 svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/icons/Receiver.svg")}
                            ></SVG>
                          </span>
                          <div className="d-flex flex-column flex-grow-1">
                            {LoadInformations?.data?.Alert !== null &&
                            LoadInformations?.data?.Alert !== undefined &&
                            LoadInformations?.data?.Alert !== "" ? (
                              <span className="alert-loads">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip
                                      style={{ pointerEvents: "none" }}
                                      id="products-delete-tooltip"
                                    >
                                      {LoadInformations?.data?.Alert}
                                    </Tooltip>
                                  }
                                >
                                  <i class="fa fa-exclamation-triangle text-danger icon-xl"></i>
                                </OverlayTrigger>
                              </span>
                            ) : (
                              ""
                            )}
                            <span className="separator separator-dashed separator-border-2 separator-secondary" />
                          </div>
                          <span className="svg-icon svg-icon-2 svg-icon-info">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                fill="currentColor"
                              />
                              <path
                                d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </div>

                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            {LoadInformations?.data?.Mode === "Drayage" &&
                            LoadInformations?.data?.IsStayWith === true ? (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  Reported empty
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ReportedEmpty !==
                                  null
                                    ? moment(
                                        LoadInformations?.data
                                          ?.ReportedEmpty
                                      ).format("MM/DD/YYYY")
                                    : "- - - -"}
                                </span>
                              </>
                            ) : (
                              <>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  MABD
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.Mabd !== null
                                    ? moment(
                                        LoadInformations?.data?.Mabd
                                      ).format("MM/DD/YYYY")
                                    : "- - - -"}
                                </span>
                              </>
                            )}
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Del_Appointment")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.data?.DelAppointmentDate !==
                              null
                                ? ConvertToDateCurrentTimeZone(
                                    LoadInformations?.data?.DelAppointmentDate
                                  )
                                : "- - - -"}
                            </span>
                            <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                              {t("Receiving_Arrival")}
                            </p>
                            <span className="text-dark-75 font-weight-bold">
                              {LoadInformations?.data?.ReceivingArrivalDate !==
                              null
                                ? ConvertToDateCurrentTimeZone(
                                    LoadInformations?.data?.ReceivingArrivalDate
                                  )
                                : "- - - -"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <Col xl="12">
                        <Row className="align-items-center mt-10">
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Equipment_Type")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {LoadInformations?.data?.EquipmentType}
                              </span>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Total_Weight")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {LoadInformations?.data?.Weight !== null &&
                                LoadInformations?.data?.Weight !== undefined
                                  ? LoadInformations?.data?.Weight +
                                    " " +
                                    (LoadInformations?.data?.WeightType !==
                                      null &&
                                    LoadInformations?.data?.WeightType !==
                                      undefined
                                      ? LoadInformations?.data?.WeightType
                                      : "")
                                  : ""}
                              </span>
                            </div>
                          </Fragment>

                          <Fragment>
                            {LoadInformations?.data?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Total_Stops")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.StopsCount || 0}
                                </span>
                              </div>
                            )}
                          </Fragment>

                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Total_Price")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                $ {LoadInformations?.data?.Price}
                              </span>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Distance_Mile")}
                              </p>
                              <span className="text-dark-75 font-weight-bold">
                                {LoadInformations?.data?.Miles} miles
                              </span>
                            </div>
                          </Fragment>
                        </Row>
                      </Col>
                      <Col xl="12">
                        <Row className="align-items-center mt-10">
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                Shipper
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.data?.Shipper}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Driver")}
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.data?.DriverFirstName + " "+ LoadInformations?.data?.DriverLastName}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Email")}
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {
                                    LoadInformations?.data?.DriverEmail
                                  }
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Licence")} #
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {
                                    LoadInformations?.data?.DriverLicence
                                  }
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            {/* <div className="d-flex flex-column flex-grow-1">
      <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
        {t("Work_Phone")} #
      </p>
      <div className="mb-10">
        <span className="font-weight-bolder text-dark-75 font-size-md">
          {LoadInformations?.Trucker?.WorkPhoneNumber}
        </span>
      </div>
    </div> */}
                          </Fragment>
                        </Row>
                        <Row>
                          <Fragment>
                            <div className="d-flex flex-column flex-grow-1">
                              <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                {t("Seal_Number")} #
                              </p>
                              <div className="mb-10">
                                <span className="font-weight-bolder text-dark-75 font-size-md">
                                  {LoadInformations?.data?.SealNumber}
                                </span>
                              </div>
                            </div>
                          </Fragment>
                          <Fragment>
                            {LoadInformations?.data?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Truck_Number")} #
                                </p>
                                <div className="mb-10">
                                  <span className="font-weight-bolder text-dark-75 font-size-md">
                                    {LoadInformations?.data?.TruckNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Fragment>
                          <Fragment>
                            {LoadInformations?.data?.Mode !== "Drayage" && (
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                  {t("Trailer_Number")} #
                                </p>
                                <div className="mb-10">
                                  <span className="font-weight-bolder text-dark-75 font-size-md">
                                    {LoadInformations?.data?.TrailerNumber}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        </Row>
                        <Row>
                          {customFields.length > 0 && (
                            <Fragment>
                              {customFields?.map((item, index) => {
                                switch (item?.Type) {
                                  case "Multi Select":
                                    return (
                                      <Col md="4" xs="6" key={index}>
                                        <div className="d-flex flex-column flex-grow-1">
                                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                            {item?.Label}
                                          </p>
                                          <div className="d-inline">
                                            {item?.Answers?.map(
                                              (option, index) => {
                                                return (
                                                  <span className="badge badge-light fw-bold my-2 mr-2">
                                                    {option}
                                                  </span>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    );
                                  default:
                                    return (
                                      <Col md="4" xs="6" key={index}>
                                        <div className="d-flex flex-column flex-grow-1">
                                          <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1">
                                            {item.Label}
                                          </p>
                                          <div className="mb-10">
                                            <span className="font-weight-bolder text-dark-75 font-size-md">
                                              {item.Answers}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>
                                    );
                                }
                              })}
                            </Fragment>
                          )}
                        </Row>
                      </Col>
                    </div>
                  </div>
                  <div className={currentTab === 1 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Container")} #
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ContainerNumber ??
                                    "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Release")} #
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ReleaseNumber ??
                                    "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Last_free_date")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.LastFreeDate !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        LoadInformations?.data?.LastFreeDate
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Vessel_eta")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.VesselEta !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        LoadInformations?.data?.VesselEta
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Available_eta")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.AvailableEta !== null
                                    ? ConvertToDateCurrentTimeZone(
                                        LoadInformations?.data?.AvailableEta
                                      )
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress
                                    ?.Name !== null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.Name
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress
                                    ?.ContactFirstName !== null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.ContactFirstName
                                    : "- - - -"}
                                </span>{" "}
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress
                                    ?.ContactLastName !== null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.ContactLastName
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress
                                    ?.ContactPhoneNumber !== null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.ContactPhoneNumber
                                    : "- - - -"}
                                </span>{" "}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress
                                    ?.ContactPhoneNumber2 !== null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.ContactPhoneNumber2
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ShippingNote !== null
                                    ? LoadInformations?.data?.ShippingNote
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col className="col-8">
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.PickupAddress !==
                                  null
                                    ? LoadInformations?.data?.PickupAddress
                                        ?.ContactEmail
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={currentTab === 2 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("DEL")}
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0]
                                      ?.DestinationNumber !== null
                                      ? LoadInformations?.data?.Stops[0]
                                          ?.DestinationNumber
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationNumber !== null
                                      ? LoadInformations?.data
                                          ?.DestinationNumber
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0]?.Name !==
                                    null
                                      ? LoadInformations?.data?.Stops[0]?.Name
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationAddress !== null
                                      ? LoadInformations?.data
                                          ?.DestinationAddress?.Name
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0] !== null
                                      ? `${LoadInformations?.data?.Stops[0]?.ContactFirstName} ${LoadInformations?.data?.Stops[0]?.ContactLastName}`
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationAddress !== null
                                      ? `${LoadInformations?.data?.DestinationAddress?.ContactFirstName} ${LoadInformations?.data?.DestinationAddress?.ContactLastName}`
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0]
                                      ?.ContactEmail !== null
                                      ? LoadInformations?.data?.Stops[0]
                                          ?.ContactEmail
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationAddress !== null
                                      ? LoadInformations?.data
                                          ?.DestinationAddress?.ContactEmail
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0]
                                      ?.ContactPhoneNumber !== null
                                      ? LoadInformations?.data?.Stops[0]
                                          ?.ContactPhoneNumber
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationAddress !== null
                                      ? LoadInformations?.data
                                          ?.DestinationAddress
                                          ?.ContactPhoneNumber
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0] !== null
                                      ? LoadInformations?.data?.Stops[0]
                                          ?.ContactPhoneNumber2
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data
                                      ?.DestinationAddress !== null
                                      ? LoadInformations?.data
                                          ?.DestinationAddress
                                          ?.ContactPhoneNumber2
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                {LoadInformations?.data?.IsStayWith === true ? (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.Stops[0] !== null
                                      ? LoadInformations?.data?.Stops[0]?.Note
                                      : "- - - -"}
                                  </span>
                                ) : (
                                  <span className="text-dark-75 font-weight-bold">
                                    {LoadInformations?.data?.ReceivingNote !==
                                    null
                                      ? LoadInformations?.data?.ReceivingNote
                                      : "- - - -"}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={currentTab === 3 ? "" : "d-none"}>
                    <div className="mt-5">
                      <div className="justify-content-between">
                        <div className="d-flex flex-wrap">
                          <div className="d-flex flex-column flex-grow-1">
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Company_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data
                                    ?.DestinationAddress !== null
                                    ? LoadInformations?.data?.DestinationAddress
                                        ?.Name
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Full_Name")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data
                                    ?.DestinationAddress !== null
                                    ? `${LoadInformations?.data?.DestinationAddress?.ContactFirstName} ${LoadInformations?.data?.DestinationAddress?.ContactLastName}`
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("DEL")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.DestinationNumber !==
                                  null
                                    ? LoadInformations?.data?.DestinationNumber
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Email")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data
                                    ?.DestinationAddress !== null
                                    ? LoadInformations?.data?.DestinationAddress
                                        ?.ContactEmail
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 1
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data
                                    ?.DestinationAddress !== null
                                    ? LoadInformations?.data?.DestinationAddress
                                        ?.ContactPhoneNumber
                                    : "- - - -"}
                                </span>
                              </Col>
                              <Col>
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Phone_Number")} 2
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data
                                    ?.DestinationAddress !== null
                                    ? LoadInformations?.data?.DestinationAddress
                                        ?.ContactPhoneNumber2
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {" "}
                                <p className="font-weight-bold text-muted text-hover-muted font-size-sm mb-1 mt-3">
                                  {t("Note")}
                                </p>
                                <span className="text-dark-75 font-weight-bold">
                                  {LoadInformations?.data?.ReceivingNote !==
                                  null
                                    ? LoadInformations?.data?.ReceivingNote
                                    : "- - - -"}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="7" lg="7" md="7" xs="12">
              <div className="maps-section mb-5">
                <GoogleMapnewversion
                  DetailTrakingGeometryInfo={DetailTrakingGeometryInfo}
                  LoadInformations={LoadInformations}
                  className="card-stretch gutter-b"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal Assign Load */}
      <ModalIndex
        show={modalBiding}
        onHide={() => setModalBiding(false)}
        size="md"
        title={`${
          LoadInformations?.data?.BidDetails !== null ? "Update" : "Add"
        } bid for load# ${LoadInformations?.data?.Code}`}
        content={
          <Fragment>
            <Row>
              <Col>
                <Row>
                  <Col lg="6">
                    <div class="form-group">
                      <label>{t("Price")}</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        <input
                          placeholder="$"
                          type="text"
                          value={bidData?.Price}
                          className="form-control"
                          onChange={(e) => {
                            let data = { ...bidData };
                            data.Price = e.target.value;
                            setBidData(data);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="mt-md-12">
                    <span className="pr-3">
                      {LoadInformations?.data?.BidDetails !== null
                        ? t("Current_bid_price")
                        : t("Current_price")}
                    </span>
                    <label className="font-weight-bolder">
                      ${LoadInformations?.data?.Price}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-md-12">
                    <div className="d-flex">
                      <div className="checkbox-list d-flex pl-0">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={bidData?.IsQuickPay}
                            id="RequestQP"
                            onChange={(e) => {
                              let data = { ...bidData };
                              data.IsQuickPay = e.target.checked;
                              setBidData(data);
                            }}
                            name="RequestQP"
                            className="mr-2"
                          />
                          <span className="mb-auto"></span>
                          <div className="d-flex flex-column flex-grow-1">
                            <label className="form-check-label" for="RequestQP">
                              {t("Request_for_Quick_Pay")}
                            </label>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        }
        footerContent={
          <Fragment>
            {" "}
            <button
              onClick={() => setModalBiding(false)}
              className="mr-3 btn btn-light btn-elevate"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              disabled={loaderBid}
              onClick={async () => {
                setLoaderBid(true);
                await AddBid();
              }}
              className="btn btnPrimaryTaap"
            >
              {LoadInformations?.data?.BidDetails !== null
                ? t("Update_bid")
                : t("Add_bid")}
              {loaderBid && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Fragment>
        }
      />
    </>
  );
};
